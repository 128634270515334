.bn-container.firstTimeDeposit {
  .bn-info {
    @include rtl-sass-prop(margin-left, margin-right, 5%);
    display: flex;
    align-items: center;
    max-width: 60%;
    .bn-info-context {
      line-height: 1;
      @include rtl-sass-value(text-align, left, right);
      font-size: 52px;
      color: $white;

      /deep/ .title {
        margin-bottom: 20px;
      }

      /deep/ .desc {
        font-weight: 400;
        font-size: 40px;
        line-height: 1.4;
      }
    }
  }
  .right-side-img {
    width: 548px;
    top: -28%;
  }
}

.bn-container.firstTimeDeposit.mid-long {
  .bn-info {
    .bn-info-context {
      /deep/ .title {
        font-size: 50px;
      }

      /deep/ .desc {
        font-size: 35px;
      }
    }
  }
}

.bn-container.firstTimeDeposit.mid {
  .bn-info {
    max-width: 55%;
    .bn-info-context {
      /deep/ .title {
        font-size: 40px;
      }

      /deep/ .desc {
        font-size: 28px;
      }
    }
  }
  .right-side-img {
    width: 480px;
  }
}

.bn-container.firstTimeDeposit.mid-short {
  .bn-info {
    max-width: 55%;
    .bn-info-context {
      /deep/ .title {
        font-size: 28px;
      }

      /deep/ .desc {
        font-size: 20px;
      }
    }
  }
  .right-side-img {
    width: 400px;
    top: -15%;
  }
}

.bn-container.firstTimeDeposit.short {
  .bn-info {
    max-width: 55%;
    .bn-info-context {
      /deep/ .title {
        font-size: 28px;
      }

      /deep/ .desc {
        font-size: 20px;
      }
    }
  }
  .right-side-img {
    width: 360px;
    @include rtl-sass-value(right, -4%, auto);
    @include rtl-sass-value(left, auto, 0);
    top: -11%;
  }
}

.bn-container.firstTimeDeposit.x-short {
  .bn-info {
    max-width: 50%;
    .bn-info-context {
      /deep/ .title {
        font-size: 28px;
      }

      /deep/ .desc {
        font-size: 20px;
      }
    }
  }
  .right-side-img {
    width: 360px;
    @include rtl-sass-value(right, -4%, auto);
    @include rtl-sass-value(left, auto, 0);
    top: -11%;
  }
}

.bn-container.firstTimeDeposit.xx-short {
  .bn-info {
    max-width: 90%;
    height: auto;
    padding-top: 20px;

    .bn-info-context {
      margin: auto;
      /deep/ .title {
        font-size: 20px;
        text-align: center;
        margin-bottom: 12px;
      }

      /deep/ .desc {
        font-size: 12px;
        text-align: center;
      }
    }
  }
  .right-side-img {
    width: 280px;
    left: 50%;
    transform: translate(-50%);
    top: 43%;
  }
}
