/deep/ .el-carousel {
  cursor: pointer;
  position: relative;

  .info {
    color: #e9e9e9;
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translate(-5%, -50%);
    text-align: right;

    h2 {
      font-size: 40px;
      color: white;
      line-height: 0.6;
      text-transform: uppercase;
      font-weight: bold;
    }

    h4 {
      font-size: 30px;
      color: white;
      line-height: 1.079;
      margin-top: 20px;
    }
  }

  .left {
    left: 5%;
    text-align: left;
    transform: translate(5%, -50%);
  }

  .el-icon-arrow-left,
  .el-icon-arrow-right {
    transform: rotate(0deg) !important;
  }

  .el-carousel__indicators--horizontal {
    left: 0;
    transform: translateX(12px);

    .el-carousel__indicator .el-carousel__button {
      border-radius: 50%;
      background-color: $blue;
      width: 16px;
      height: 16px;
    }

    .el-carousel__indicator.is-active .el-carousel__button {
      background-color: $red;
    }
  }
}

@media (max-width: 500px) {
  /deep/ .el-carousel .info {
    left: 0%;
    text-align: center;
    right: 0;
    transform: translate(-0%, -50%);

    h2 {
      font-size: 32px;
    }
    h4 {
      font-size: 20px;
    }
  }
}
