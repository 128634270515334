.bn-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  .bn-info {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .bn-info-context {
      font-size: 60px;
      font-weight: 800;
      line-height: 1.2;
      @include rtl-sass-value(float, right, left);

      /deep/ .bg_yellow {
        color: #ffcb00;
      }
    }
  }

  .left-side-img {
    max-width: max-content;
    position: absolute;
    top: 0;
    width: 50%;
    @include rtl-sass-prop-dual(left, 0, right, 0);
  }

  .right-side-img {
    max-width: max-content;
    position: absolute;
    top: 0;
    width: 50%;
    @include rtl-sass-prop-dual(right, 0, left, 0);
  }

  // styles for area which 'dir' always set to 'ltr'
  &.ltr {
    .bn-info {
      .bn-info-context {
        float: right !important;
      }
    }

    .left-side-img {
      left: 0 !important;
    }

    .right-side-img {
      right: 0 !important;
    }
  }
}
