.bn-container.millionDollarCashPool {
  .bn-info {
    margin-right: 10%;
    display: flex;
    align-items: center;
    left: 40%;
    width: 50%;

    .bn-info-context {
      line-height: 1;
      text-align: left;
      font-size: 52px;
    }
  }
  .left-side-img {
    margin-left: 8%;
    width: 548px;
    top: -30%;
  }
}

.bn-container.millionDollarCashPool.mid-long {
  .bn-info {
    .bn-info-context {
      font-size: 44px;
    }
  }
  .left-side-img {
    margin-left: 2%;
  }
}

.bn-container.millionDollarCashPool.mid {
  .bn-info {
    left: 440px;
    .bn-info-context {
      font-size: 36px;
    }
  }
  .left-side-img {
    width: 480px;
    top: -20%;
    margin-left: -4%;
  }
}

.bn-container.millionDollarCashPool.mid-short {
  .bn-info {
    left: 440px;
    margin-right: 2%;
    .bn-info-context {
      font-size: 28px;
    }
  }
  .left-side-img {
    width: 480px;
    top: -20%;
    margin-left: -4%;
  }
}

.bn-container.millionDollarCashPool.short {
  .bn-info {
    margin-right: 2%;
    left: 320px;
    .bn-info-context {
      font-size: 28px;
    }
  }
  .left-side-img {
    width: 360px;
    top: -10%;
    margin-left: -4%;
  }
}

.bn-container.millionDollarCashPool.xShort {
  overflow-x: hidden;
  .bn-info {
    display: none;
    left: 340px;
  }
  .left-side-img {
    width: 380px;
    top: -5%;
    margin: auto;
  }
}
